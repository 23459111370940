<div class="container py-5 mb-5">
  <div class="row">
    <div class="col-12 col-md-8" *ngIf="!submitted">
      <h2 class="font-weight-bold">Kontaktformular</h2>
      <form [formGroup]="formGroup">
        <div class="d-flex flex-column mb-3">
          <label class="mb-0">Name</label>
          <input type="text" formControlName="name" class="outline-none border-0 bg-light-grey p-2">
          <small *ngIf="(formGroup.get('name').dirty || formGroup.get('name').touched) && formGroup.get('name').hasError('required')">
            Bitte geben Sie Ihren Namen an.
          </small>
        </div>
        <div class="d-flex flex-column mb-3">
          <label class="mb-0">Unternehmen <span class="color-grey">(optional)</span></label>
          <input type="text" formControlName="company" class="outline-none border-0 bg-light-grey p-2">
        </div>
        <div class="d-flex flex-column mb-3">
          <label class="mb-0">E-Mail</label>
          <input type="email" formControlName="email" class="outline-none border-0 bg-light-grey p-2">
          <small *ngIf="(formGroup.get('email').dirty || formGroup.get('email').touched) && formGroup.get('email').invalid">
            Bitte geben Sie Ihre E-Mail-Adresse an.
          </small>
        </div>
        <div class="d-flex flex-column mb-3">
          <label class="mb-0">Nachricht</label>
          <textarea rows="5" formControlName="message" class="outline-none border-0 bg-light-grey p-2"></textarea>
          <small *ngIf="(formGroup.get('message').dirty || formGroup.get('message').touched) && formGroup.get('message').invalid">
            Bitte geben Sie mindestens 30 Zeichen ein.
          </small>
        </div>
        <div class="custom-control custom-checkbox mb-4">
          <input type="checkbox" class="custom-control-input" id="privacyCheckbox" formControlName="privacyAccepted">
          <label class="custom-control-label" for="privacyCheckbox">
            Ich akzeptiere die <a class="unstyled font-weight-bold" routerLink="/datenschutz">Datenschutzbestimmungen</a>
            und bin damit einverstanden, dass ich über meine angegebene E-Mail-Adresse kontaktiert werde.
          </label>
        </div>
        <button class="comp-button" (click)="submit()" [disabled]="formGroup.invalid">Absenden</button>
      </form>
    </div>
    <div class="col-12 col-md-8" *ngIf="submitted && !(success || failure)">
      <h2 class="bold">Bitte warten...</h2>
      <p>
        Ihre Anfrage wird gesendet. Bitte warten Sie einen Moment.
      </p>
    </div>
    <div class="col-12 col-md-8" *ngIf="submitted && success">
      <h2 class="bold">Alles erledigt</h2>
      <p>
        Wir haben Ihre Nachricht erhalten und melden uns schnellstmöglich unter
        der angegebenen E-Mail-Adresse ({{formGroup.value.email}}).
      </p>
    </div>
    <div class="col-12 col-md-8" *ngIf="submitted && failure">
      <h2 class="bold">Oops...</h2>
      <p>
        Es gab ein Problem beim Senden Ihrer Anfrage. Wir haben Ihre Nachricht gespeichert.
        Bitte <a class="unstyled font-weight-bold" [href]="failMailtoHref">klicken Sie hier</a>,
        um uns Ihre Nachricht per E-Mail zu senden.
      </p>
    </div>
    <!-- <div class="d-none d-md-block col-md-4">
      <img src="/assets/images/contact/contact.png" class="w-100">
    </div> -->
  </div>
</div>
