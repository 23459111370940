import { Component, HostListener, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { NavbarService } from '../../services/navbar.service';

export interface NavItem {
  text: string;
  href: string;
  fragment?: string;
}

@Component({
  selector: 'comp-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  activatedRoute: string;

  navItems: NavItem[] = [
    {
      text: 'Leistungen',
      href: '/',
      fragment: 'leistungen'
    },
    {
      text: 'Referenzen',
      href: '/',
      fragment: 'referenzen'
    },
    {
      text: 'Kontakt',
      href: '/kontakt'
    }
  ];

  constructor(private readonly router: Router,
              private readonly location: Location,
              public readonly navbarService: NavbarService) { }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    // lg breakpoint
    if (event.target.innerWidth > 992) {
      this.navbarService.closeNavbar();
    }
  }

  ngOnInit() {
    this.router.events.subscribe(() => {
      this.activatedRoute = this.location.path();
      this.navbarService.closeNavbar();
    });
  }

  isCurrent(navItem: NavItem): boolean {
    return navItem.href === this.activatedRoute;
  }

}
