<article itemprop="blogPost" itemscope itemtype="https://schema.org/BlogPosting">
    <meta itemprop="dateCreated datePublished" [content]="result?.first_publication_date">
    <meta itemprop="dateModified" [content]="result?.last_publication_date">
    <meta itemprop="thumbnailUrl image" [content]="result?.data.heroimage.url">
    <comp-meta-local-business></comp-meta-local-business>

    <section>
        <comp-case-detail-fullscreen-gallery></comp-case-detail-fullscreen-gallery>
        <comp-header [config]="{
            title: result.data.title[0].text,
            subtitle: result.data.subtitle[0].text
        }"></comp-header>
    </section>

    <section itemprop="articleBody text">
        <div *ngFor="let slice of result?.data?.body" [ngSwitch]="slice.slice_type">
            <div class="container py-5" *ngSwitchCase="'text'">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-8" [innerHtml]="slice.primary.text | renderHtml | safe: 'html'"></div>
                </div>
            </div>
            <div class="container mb-5" *ngSwitchCase="'image'">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-8" [innerHtml]="slice.primary.image | renderImage : '' : 'w-100' : slice.primary.description"></div>
                </div>
            </div>
            <ng-container *ngSwitchCase="'keycontent'">
                <comp-key-content [config]="RendererUtils.toKeyContentConfig(slice)"></comp-key-content>
            </ng-container>
            <div *ngSwitchCase="'video'">
                <div class="container mb-5">
                    <div class="row justify-content-center">
                        <div class="col-12 col-md-8 text-center" [innerHTML]="slice.primary.youtubevideo.html | safe: 'html'"></div>
                    </div>
                </div>
            </div>
            <div *ngSwitchDefault><pre>{{slice | json}}</pre></div>
        </div>

        <comp-image-grid [mode]="hasTextContent ? 'dark' : 'light'" [images]="result.data.impressions"></comp-image-grid>
        <comp-service-grid [config]="RendererUtils.toServiceGrid(result.data.services, 'Erbrachte Leistungen', hasTextContent ? 'light' : 'dark')"></comp-service-grid>
    </section>

    <comp-teaser [config]="teaser"></comp-teaser>

</article>
