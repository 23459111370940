import { isPlatformBrowser } from '@angular/common';
import { ErrorHandler, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { environment } from '../environments/environment';

Sentry.init({
    dsn: environment.sentry.dsn,
    environment: environment.name
});

@Injectable()
export class SentryErrorHandler implements ErrorHandler {

    constructor(@Inject(PLATFORM_ID) private readonly platformId: object) { }

    handleError(error) {
        if (isPlatformBrowser(this.platformId)) {
            Sentry.captureException(error.originalError || error);
        }
        throw error;
    }
}
