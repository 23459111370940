import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ButtonComponent } from './components/button/button.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { KeyContentComponent } from './components/key-content/key-content.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { ServiceGridComponent } from './components/service-grid/service-grid.component';
import { TeaserComponent } from './components/teaser/teaser.component';
import { NavbarCloseOnClickDirective } from './directives/navbar-close-on-click.directive';
import { WINDOW_PROVIDERS } from './services/window.service';
import { MetaLocalBusinessComponent } from './components/meta-local-business/meta-local-business.component';
import { SafePipe } from './pipes/safe.pipe';

@NgModule({
  declarations: [
    NavbarComponent,
    NavbarCloseOnClickDirective,
    KeyContentComponent,
    TeaserComponent,
    ButtonComponent,
    ServiceGridComponent,
    FooterComponent,
    HeaderComponent,
    MetaLocalBusinessComponent,
    SafePipe
  ],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [
    NavbarComponent,
    KeyContentComponent,
    TeaserComponent,
    ButtonComponent,
    ServiceGridComponent,
    FooterComponent,
    HeaderComponent,
    MetaLocalBusinessComponent
  ],
  providers: [
    WINDOW_PROVIDERS
  ]
})
export class SharedModule { }
