import { Component, OnInit, Input } from '@angular/core';

export interface ButtonConfig {
  text: string;
  routerLink: string;
  inverted?: boolean;
}

@Component({
  selector: 'comp-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {

  @Input()
  config: ButtonConfig;

  constructor() { }

  ngOnInit() {
  }

}
