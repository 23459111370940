<comp-header [config]="header"></comp-header>
<div class="container">
  <div class="row">
    <div class="col">
      <p>
        Wir freuen uns, dass Sie unsere Webseiten besuchen und bedanken uns für Ihr Interesse an unserem Unternehmen
        und unseren Webseiten. Der Schutz Ihrer Privatsphäre bei der Nutzung unserer Webseiten ist uns wichtig.
        Daher nehmen Sie bitte nachstehende Informationen sowie unsere <a routerLink="/datenschutz" class="unstyled font-weight-bold">Datenschutzerklärung</a> zur Kenntnis.
      </p>
    </div>
  </div>
  <div class="row mb-4">
    <div class="col">
      <h2 class="bold">Herausgeberin</h2>
      <p>
        Steffi Paasche<br>
        Im Wiesengrund 4<br>
        56459 Stahlhofen am Wiesensee<br>
        Deutschland
      </p>
    </div>
  </div>
  <div class="row mb-4">
    <div class="col">
      <h2 class="bold">Haftungsausschluss (Disclaimer)</h2>
      <p>
        Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen
        Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind
        wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte
        fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine
        rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung
        der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben.
      </p>
    </div>
  </div>
</div>
