import { Component, OnInit, HostListener } from '@angular/core';
import { GalleryService } from '../../services/gallery.service';

enum KEY_CODE {
  RIGHT_ARROW = 39,
  LEFT_ARROW = 37,
  ESCAPE = 27
}

@Component({
  selector: 'comp-case-detail-fullscreen-gallery',
  templateUrl: './fullscreen-gallery.component.html',
  styleUrls: ['./fullscreen-gallery.component.scss']
})
export class FullscreenGalleryComponent implements OnInit {

  constructor(public readonly gallery: GalleryService) { }

  ngOnInit() {
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    switch (event.keyCode) {
      case KEY_CODE.ESCAPE: return this.gallery.close();
      case KEY_CODE.LEFT_ARROW: return this.gallery.prev();
      case KEY_CODE.RIGHT_ARROW: return this.gallery.next();
    }
  }

}
