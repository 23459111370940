<div class="container py-5">
  <div class="row">
    <div class="col-12 col-md-6 mb-4" *ngFor="let member of members">
      <figure>
        <img [src]="member.image.src" class="w-100 mb-3">
        <figcaption>
          <h3 class="m-0">{{member.name}}</h3>
          <p>{{member.position}}</p>
          <p class="m-0">E-Mail: <a class="unstyled" [href]="'mailto:' + member.email">{{member.email}}</a></p>
          <p class="m-0">Tel.: <a class="unstyled" [href]="'tel:' + member.phone">{{member.phone}}</a></p>
        </figcaption>
      </figure>
    </div>
  </div>
</div>
