import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavbarService {

  private _isNavbarOpen = false;

  get isNavbarOpen(): boolean {
    return this._isNavbarOpen;
  }

  openNavbar() {
    this._isNavbarOpen = true;
  }

  closeNavbar() {
    this._isNavbarOpen = false;
  }

  toggleNavbar() {
    if (this._isNavbarOpen) {
      this.closeNavbar();
    } else {
      this.openNavbar();
    }
  }

}
