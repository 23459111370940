import { Injectable } from '@angular/core';
import { PrismicService, TypedDocument } from '@ngx-prismic/client';
import Prismic from 'prismic-javascript';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PrismicDocumentType } from '../models/document-types';
import { Homepage } from '../models/homepage.model';

@Injectable({
  providedIn: 'root'
})
export class HomepageService {

  constructor(private readonly prismicService: PrismicService) { }

  getHomepage(): Observable<TypedDocument<Homepage>> {
    const predicates = [
      Prismic.Predicates.at('document.type', PrismicDocumentType.HOMEPAGE)
    ];

    return this.prismicService.query<Homepage>(predicates).pipe(
      map(response => response.results[0])
    );
  }
}
