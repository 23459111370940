import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';

export interface KeyContentConfig {
  heading: string;
  subheading: string;
  text: string[];
  image?: {
    src: string;
    alt?: string;
    title?: string;
  };
  video?: string;
  slider?: {
    src: string;
    alt?: string;
    title?: string;
  }[];
}

@Component({
  selector: 'comp-key-content',
  templateUrl: './key-content.component.html',
  styleUrls: ['./key-content.component.scss']
})
export class KeyContentComponent implements OnInit, OnDestroy {

  @Input()
  config: KeyContentConfig;

  private sliderIndex = 0;
  private intervalId: number;

  constructor(
    @Inject(PLATFORM_ID)
    private readonly platformId: Object
  ) { }

  ngOnInit() {
    if (this.config.video && this.config.image) {
      this.config.image = null;
      console.debug('The KeyContent component uses the video if both video and image are specified');
    }

    if (!this.config.video && isPlatformBrowser(this.platformId)) {
      this.activateSlider();
    }
  }

  ngOnDestroy() {
    clearInterval(this.intervalId);
  }

  private activateSlider() {
    if (this.config.slider && this.config.slider.length > 0) {
      this.config.image = this.config.slider[this.sliderIndex];
      this.intervalId = setInterval(() => {
        if (this.sliderIndex === this.config.slider.length - 1) {
          this.sliderIndex = 0;
        } else {
          this.sliderIndex++;
        }
        this.config.image = this.config.slider[this.sliderIndex];
      }, 5000) as unknown as number;
    }
  }

}
