<div class="bg-dark-grey" *ngIf="cases.results_size !== 0">
  <div class="container py-5">
    <div class="row py-5">
      <div class="col">
        <h1 class="bold color-white">Referenzen</h1>
      </div>
    </div>
    
    <article class="row py-md-5 mb-5 mb-md-0"
             itemprop="blogPost" itemscope itemtype="https://schema.org/BlogPosting"
             *ngFor="let case of cases?.results; let isOdd = odd;">
      <meta itemprop="dateCreated datePublished" [content]="case?.first_publication_date">
      <meta itemprop="dateModified" [content]="case?.last_publication_date">
      <meta itemprop="identifier mainEntityOfPage url" [content]="baseUrl + '/referenzen/' + case?.uid">
      <meta itemprop="thumbnailUrl image" [content]="case.data.heroimage.url">
      <comp-meta-local-business></comp-meta-local-business>

      <div class="col-12 col-md-5 mb-3 mb-md-0" [class.order-md-2]="isOdd">
        <div class="image-container position-relative rounded w-100 mb-2 mb-md-0">
          <a class="unstyled" [routerLink]="'/referenzen/' + case.uid">
            <div [style.background-image]="'url(' + case.data.heroimage.url + ')'"></div> 
          </a>
        </div>
      </div>
      <div class="col-12 col-md-7">
        <h2 itemprop="name" class="mb-3 color-white">{{case.data.title[0].text}}</h2>
        <p itemprop="description" class="mb-5 color-white">{{case.data.description[0].text}}</p>
        <a class="unstyled color-white" [routerLink]="'/referenzen/' + case.uid">
          Referenz ansehen <i class="material-icons align-bottom">arrow_right_alt</i>
        </a>
      </div>
    </article>
  </div>
</div>
