import { Case } from './case.model';
import { Faq } from './faq.model';
import { Homepage } from './homepage.model';

/**
 * Union type for all available DocumentTypes.
 * Used as the generic type of {@link PrismicResult}
 * */
export type PrismicDocument =
    Case |
    Faq |
    Homepage;

export enum PrismicDocumentType {
    CASE = 'case',
    FAQ = 'faq',
    HOMEPAGE = 'homepage'
}
