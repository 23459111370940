import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrismicClientModule, PrismicServiceConfigProvider, PrismicServiceConfig } from '@ngx-prismic/client';
import { environment } from '../../../environments/environment';

const prismicConfig: PrismicServiceConfig = {
  prismicUrl: environment.prismic.url
};

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    PrismicClientModule
  ],
  providers: [
    {
      provide: PrismicServiceConfigProvider,
      useValue: prismicConfig
    }
  ]
})
export class PrismicModule { }
