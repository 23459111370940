import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MetaService } from '@ngx-meta/core';
import { TypedDocument } from '@ngx-prismic/client';
import { environment } from '../../../environments/environment';
import { Homepage } from '../prismic/models/homepage.model';
import { PrismicRendererUtils } from '../prismic/services/renderer-utils';
import { HeaderConfig } from '../shared/components/header/header.component';
import { KeyContentConfig } from '../shared/components/key-content/key-content.component';
import { ServiceGridConfig } from '../shared/components/service-grid/service-grid.component';
import { TeaserConfig } from '../shared/components/teaser/teaser.component';

@Component({
  selector: 'comp-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  header: HeaderConfig;
  keyContent: KeyContentConfig;
  services: ServiceGridConfig;
  teaser: TeaserConfig = {
    text: 'Ihnen gefallen unsere Referenzen und Sie planen ein Event für Ihr Unternehmen? Kontaktieren Sie uns und lassen Sie sich beraten.',
    button: {
      text: 'Jetzt Kontakt aufnehmen',
      routerLink: '/kontakt'
    }
  }

  constructor(
    private readonly route: ActivatedRoute,
    private readonly meta: MetaService
  ) { }

  ngOnInit() {
    this.mapDataFromRouteSnapshot();

    this.meta.setTitle('Home');
    this.meta.setTag('og:type', 'website');
    this.meta.setTag('description', 'Die richtige Kommunikation für Ihr Unternehmen');
    this.meta.setTag('og:description', 'Die richtige Kommunikation für Ihr Unternehmen');
    this.meta.setTag('og:title', '1punkt2');
    this.meta.setTag('og:url', `${environment.baseUrl}`);
  }

  private mapDataFromRouteSnapshot() {
    const homepage = this.route.snapshot.data.homepage as TypedDocument<Homepage>;

    this.header = {
      title: homepage.data.title[0].text,
      subtitle: homepage.data.subtitle[0].text
    };

    this.keyContent = PrismicRendererUtils.toKeyContentConfig(homepage.data.body[0]);
    this.services = PrismicRendererUtils.toServiceGrid(homepage.data.services, 'Unsere Leistungen', 'light');
  }

}
