import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PrismicClientModule } from '@ngx-prismic/client';
import { PrismicModule } from '../prismic/prismic.module';
import { SharedModule } from '../shared/shared.module';
import { CaseDetailComponent } from './case-detail.component';
import { CaseResolver } from './case.resolver';
import { FullscreenGalleryComponent } from './components/fullscreen-gallery/fullscreen-gallery.component';
import { ImageGridComponent } from './components/image-grid/image-grid.component';

@NgModule({
  declarations: [
    CaseDetailComponent,
    ImageGridComponent,
    FullscreenGalleryComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    PrismicModule,
    SharedModule,
    PrismicClientModule
  ],
  providers: [
    CaseResolver
  ]
})
export class CaseDetailModule { }
