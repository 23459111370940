<div *ngIf="gallery.isOpen">
  <div class="position-fixed content-overlay w-100">
    <div class="container h-100">
      <div class="row h-100">
        <!-- This element is at the top and therefore gets the gesture handling -->
        <div class="col-12 pt-3 text-right"
             (swipeleft)="gallery.prev()"
             (swiperight)="gallery.next()">
          <i class="material-icons cursor-pointer" (click)="gallery.close()">close</i>
        </div>
      </div>
      <div class="row d-flex align-self-end align-items-between">
        <div class="col">
          <i class="material-icons cursor-pointer" (click)="gallery.prev()">chevron_left</i>
        </div>
        <div class="col text-right">
          <i class="material-icons cursor-pointer" (click)="gallery.next()">chevron_right</i>
        </div>
      </div>
    </div>
  </div>
  <div [class.overlay]="gallery.isOpen">
    <div class="container h-100">
      <div class="row h-100">
        <div class="col d-flex align-items-center justify-content-center">
          <img class="center-cropped" [src]="gallery.currentImage">
        </div>
      </div>
    </div>
  </div>
</div>
