import { Component, OnInit, Input } from '@angular/core';
import { ButtonConfig } from '../button/button.component';

export interface TeaserConfig {
  text: string;
  button: ButtonConfig;
  inverted?: boolean;
}

@Component({
  selector: 'comp-teaser',
  templateUrl: './teaser.component.html',
  styleUrls: ['./teaser.component.scss']
})
export class TeaserComponent implements OnInit {

  @Input()
  config: TeaserConfig;

  constructor() { }

  ngOnInit() {
    if (this.config.inverted) {
      this.config.button.inverted = true;
    }
  }

}
