import { Injectable } from '@angular/core';
import { PrismicPageOptions, PrismicService, TypedApiSearchResponse, TypedDocument } from '@ngx-prismic/client';
import Prismic from 'prismic-javascript';
import { QueryOptions } from 'prismic-javascript/types/ResolvedApi';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Case } from '../models/case.model';
import { PrismicDocumentType } from '../models/document-types';
import { KeyContent, SliceType } from '../models/shared/slices.model';

const guardAgainstMissingImages = (response: TypedApiSearchResponse<Case>) => {
  for (const result of response.results) {
    if (result.data.body[0].slice_type == SliceType.KEYCONTENT) {
      const keyContentSlice = result.data.body[0] as KeyContent;

      // Guard against missing hero image
      if (!(result.data.heroimage && result.data.heroimage.url)) {
        // Take image from "key-content" slice
        if (keyContentSlice.primary.image && keyContentSlice.primary.image.url) {
          result.data.heroimage = keyContentSlice.primary.image;
          continue;
        }
      }

      // Guard against missing image in first key-content
      if (!((keyContentSlice.primary.video && keyContentSlice.primary.video[0]) || (keyContentSlice.primary.image && keyContentSlice.primary.image.url))) {
        // Take image from hero image
        if (result.data.heroimage && result.data.heroimage.url) {
          keyContentSlice.primary.image = result.data.heroimage;
          continue;
        }
      }
    }
  }

  return response;
};

@Injectable({
  providedIn: 'root'
})
export class CaseService {

  constructor(private readonly prismicService: PrismicService) { }

  getCases(pageOptions?: PrismicPageOptions): Observable<TypedApiSearchResponse<Case>> {
    const predicates = [
      Prismic.Predicates.at('document.type', PrismicDocumentType.CASE)
    ];

    const options: QueryOptions = {
      ...pageOptions,
      orderings : '[document.first_publication_date]'
    };

    return this.prismicService.query<Case>(predicates, options)
      .pipe(
        map(response => guardAgainstMissingImages(response))
      );
  }

  getCase(uid: string): Observable<TypedDocument<Case>> {
    const predicates = [
      Prismic.Predicates.at('document.type', PrismicDocumentType.CASE),
      Prismic.Predicates.at('my.case.uid', uid)
    ];

    return this.prismicService.query<Case>(predicates)
      .pipe(
        map(response => guardAgainstMissingImages(response).results[0])
      );
  }
}
