import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { TypedApiSearchResponse } from '@ngx-prismic/client';
import { Observable, of } from 'rxjs';
import { Case } from '../prismic/models/case.model';
import { CaseService } from '../prismic/services/case.service';
import { catchError } from 'rxjs/operators';

@Injectable()
export class HomeCaseResolver implements Resolve<TypedApiSearchResponse<Case>> {

  constructor(
    private readonly caseService: CaseService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<TypedApiSearchResponse<Case>> {
    return this.caseService.getCases({
        page: 1,
        pageSize: 10
    }).pipe(
        catchError(err => {
            console.log('Failed to load cases from Prismic.', err);
            const fallbackResponse: TypedApiSearchResponse<Case> = {
                results: [],
                license: null,
                next_page: null,
                prev_page: null,
                page: null,
                results_per_page: null,
                results_size: 0, // Used to check if results are there. Section will be hidden if === 0
                total_pages: null,
                total_results_size: null,
                version: null
            };
            return of(fallbackResponse);
        })
    );
  }
}
