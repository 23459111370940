<comp-header [config]="header"></comp-header>
<div class="container">
  <div class="row">
    <div class="col">
      <h2 class="bold">Präambel</h2>
      <p>
        Unser Ziel ist es, in einer vertrauensvollen und kooperativen Art und Weise mit sämtlichen Interessenten, Kunden und Geschäftspartnern zusammenzuarbeiten. Dazu gehört auch der verantworungsvolle Umgang mit Daten. Wir nehmen daher den Schutz Ihrer sowie unserer personenbezogenen Daten sehr ernst und gehen daher sowohl moralisch als auch technisch gewissenhaft mit erhobenen Daten um.
      </p>
      <p>
        Wir möchten Ihnen nachfolgend erläutern, welche Daten wir wann und zu welchem Zweck erheben, verarbeiten und nutzen. Hierbei soll dargelegt werden, wie unsere angebotenen Dienste arbeiten und wie hierbei der Schutz Ihrer personenbezogenen Daten gewährleistet wird.
      </p>
      <p>
        Wir erheben, verarbeiten und nutzen grundsätzlich nur personenbezogene Daten, sofern Sie damit ausdrücklich einverstanden sind oder ein Gesetz die Erhebung gestattet.
      </p>
      <p>
        Diese Datenschutzerklärung kann im Zuge der Weiterentwicklung unserer Webseite sowie zur Einhaltung gesetzlicher Vorschriften von Zeit zu Zeit angepasst werden. Wir empfehlen Ihnen daher, unsere Datenschutzerklärung gelegentlich erneut durchzulesen. Unsere Datenschutzerklärung kann jederzeit unter der URL https://www.1punkt2.de/datenschutz abgerufen, abgespeichert und ausgedruckt werden.
      </p>

      <h2 class="bold">Verantwortliche Stelle</h2>
      <p>Verantwortliche Stelle im Sinne der Datenschutzgesetze ist:</p>
      <p>
        Steffi Paasche<br>
        Im Wiesengrund 4<br>
        56459 Stahlhofen am Wiesensee<br>
        Deutschland
      </p>
      <p>
        Bei allgemeinen Fragen oder Anregungen an uns zum Thema Datenschutz können Sie sich jederzeit per E-Mail an <a class="unstyled font-weight-bold" href="mailto:info@1punkt2.de">info@1punkt2.de</a> mit uns in Verbindung setzen.
      </p>

      <h2 class="bold">Recht auf Widerrug</h2>
      <p>
        Nur mit Ihrer ausdrücklichen Einwilligung sind einige Vorgänge der Datenverarbeitung möglich. Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
      </p>

      <h2 class="bold">Recht auf Beschwerde bei der zuständigen Aufsichtsbehörde</h2>
      <p>
        Als Betroffener steht Ihnen im Falle eines datenschutzrechtlichen Verstoßes ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Zuständige Aufsichtsbehörde bezüglich datenschutzrechtlicher Fragen ist der Landesdatenschutzbeauftragte des Bundeslandes, in dem sich der Sitz unseres Unternehmens befindet. Der folgende Link stellt eine Liste der Datenschutzbeauftragten sowie deren Kontaktdaten bereit:
      </p>
      <p>
        <a class="unstyled font-weight-bold" href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html" target="_blank" rel="noopener">www.bfdi.bund.de</a>
      </p>

      <h2 class="bold">Recht auf Datenübertragbarkeit</h2>
      <p>
        Ihnen steht das Recht zu, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an Dritte aushändigen zu lassen. Die Bereitstellung erfolgt in einem maschinenlesbaren Format. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.
      </p>

      <h2 class="bold">Allgemeines zur Verarbeitung personenbezogener Daten</h2>
      <p>
        Diese Datenschutzerklärung gilt für Daten, einschließlich personenbezogener Daten, die über Sie von der 1punkt2 UG (haftungsbeschränkt) gesammelt werden. Personenbezogene Daten sind Daten oder eine Kombination aus Einzeldaten, durch die Sie identifiziert werden können.
      </p>
      <p>
        Wir verarbeiten Ihre personenbezogenen Daten unter Einhaltung der Datenschutzgesetze der Bundesrepublik Deutschland und der europäischen Datenschutzgrundverordnung. In keinem Fall werden wir Ihre personenbezogenen Daten ohne Ihre Einwilligung zu Werbe- oder Marketingzwecken an Dritte weitergeben.
      </p>
      <p>
        Als Unternehmen bedienen wir uns externer Dienstleister. Soweit es sich bei den verarbeiteten Daten um personenbezogene Inhalte handelt, wurden gemäß geltendem Recht entsprechende vertragliche Vereinbarungen und organisatorische Maßnahmen getroffen, die die Sicherheit Ihrer Daten gewährleisten.
      </p>
      <p>
        Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich. Soweit auf unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder E-Mail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf freiwilliger Basis.
      </p>
      <p>
        Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Wir versuchen, durch Vorkehrungen wie Pseudonymisierung, Datensparsamkeit, Beachtung von Löschfristen und unter Berücksichtigung des aktuellen Stands der Technik Ihre Daten vor dem unberechtigten Zugriff Dritter zu schützen. Trotz dieser Schutzmaßnahmen können wir jedoch eine unrechtmäßige Verarbeitung durch Dritte nicht vollständig ausschließen.
      </p>

      <h2 class="bold">Datenerhebung und -verarbeitung</h2>
      <p>
        Im Rahmen unserer Geschäftsbeziehung müssen Sie die personenbezogenen Daten bereitstellen, die für die Aufnahme und Durchführung der jeweiligen Geschäftsbeziehung und der Erfüllung der damit verbundenen vertraglichen Pflichten erforderlich sind oder zu deren Erhebung wir gesetzlich verpflichtet sind.
      </p>
      <p>
        Ohne diese Daten werden wir in der Regel nicht in der Lage sein, die Geschäftsbeziehung mit Ihnen einzugehen und die sich hieraus ergebenden Pflichten zu erfüllen. Wir verarbeiten personenbezogene Daten, die wir im Rahmen unserer Geschäftsbeziehung von Ihnen erhalten.
      </p>
      <p>
        Die Daten erhalten wir direkt von Ihnen, z. B. im Rahmen von Anfragen, Bestellungen oder Newsletterabonnements oder durch den persönlichen Kontakten mit unseren Mitarbeitern. Zudem verarbeiten wir – soweit für die Erbringung unserer Dienstleistung erforderlich – Ihre personenbezogenen Daten, die wir aus öffentlich zugänglichen Quellen (z. B. Handels- und Vereinsregister, Presse, Internet) zulässigerweise gewinnen.
      </p>
      <p>
        Konkret verarbeiten wir u.a. folgende Daten:
      </p>
      <ul>
        <li>Stammdaten zur Kontaktaufnahme (z. B. Name, Adresse, Kontaktdaten)</li>
        <li>Auftragsdaten (z. B. im Rahmen von Bestellvorgängen)</li>
        <li>Dokumentationsdaten (z. B. Gesprächsnotizen, Besuchsprotokolle)</li>
        <li>Daten zur Anbahnung und der Durchführung unserer Geschäftsverhältnisse</li>
        <li>Korrespondenz (z. B. Schriftverkehr)</li>
        <li>Werbe- und Vertriebsdaten (z. B. über für Sie potentiell interessante Produkte)</li>
      </ul>
      <p>
        Wir verarbeiten Ihre personenbezogenen Daten im Einklang mit den Bestimmungen der Europäischen Datenschutzgrundverordnung (DSGVO) und dem Bundesdatenschutzgesetz (BDSG). Im Folgenden informieren wir Sie, auf welcher Rechtsgrundlage wir Ihre Daten verarbeiten.
      </p>

      <h3>Aufgrund Ihrer Einwilligung</h3>
      <p>
        Soweit Sie uns eine Einwilligung zur Verarbeitung von personenbezogenen Daten für bestimmte Zwecke erteilt haben, ist die jeweilige Einwilligung Rechtsgrundlage für die dort genannte Verarbeitung.
      </p>
      <p>
        Dies gilt insbesondere bei:
      </p>
      <ul>
        <li>Werblicher Ansprache per E-Mail und/oder Telefon sowie Entwicklung und Bereitstellung von Werbung, die an Ihre Interessen angepasst ist</li>
        <li>Versand von Produkten und Informationen</li>
        <li>Lieferung sonstiger Dienstleistungen, die wir Ihnen angeboten haben</li>
        <li>Umfragen auf unseren Websites</li>
        <li>Übermittlung von Daten innerhalb der 1punkt2 UG (haftungsbeschränkt)</li>
        <li>Übermittlung von Daten an Dritte</li>
      </ul>
      <p>
        Sie können eine erteilte Einwilligung jederzeit widerrufen. Dies gilt auch für den Widerruf von Einwilligungserklärungen, die Sie uns vor der Geltung der DSGVO, also vor dem 25. Mai 2018, erteilt haben. Der Widerruf der Einwilligung wirkt nur für zukünftige Verarbeitungen.
      </p>

      <h3>Zur Erfüllung von vertraglichen Pflichten (Art. 6 Abs. 1b DSGVO)</h3>
      <p>
        Die Verarbeitung von Daten erfolgt zur Erfüllung eines Vertrages mit Ihnen oder zur Durchführung vorvertraglicher Maßnahmen, die auf Grundlage Ihrer Anfrage erfolgen. Die Zwecke der Datenverarbeitung richten sich im Einzelnen nach der konkreten Geschäftsbeziehung oder der konkreten Beauftragung durch Sie.
      </p>

      <h3>Aufgrund gesetzlicher Vorgaben (Art. 6 Abs. 1c DSGVO)</h3>
      <p>
        Wir unterliegen verschiedenen rechtlichen Verpflichtungen, z. B. durch das Handelsgesetz oder die Gewerbeordnung. Zu den Zwecken der Verarbeitung gehören u.a.
      </p>
      <ul>
        <li>Durchsetzung unserer allgemeinen Geschäftsbedingungen</li>
        <li>Verwaltung unseres Geschäfts</li>
        <li>Verarbeitung zur Erfüllung der gesetzlichen Aufbewahrungs- oder Dokumentationspflichten</li>
      </ul>

      <h3>Im Rahmen der Interessenabwägung (Art. 6 Abs. 1f DSGVO)</h3>
      <p>
        Soweit erforderlich verarbeiten wir Ihre Daten über die eigentliche Erfüllung des Vertrages hinaus zur Wahrung berechtigter Interessen von uns oder Dritten. Dies erfolgt u.a. zu folgenden Zwecken:
      </p>
      <ul>
        <li>Allgemeine Geschäftssteuerung</li>
        <li>Prüfung, Optimierung und Weiterentwicklung von Produkten und Dienstleistungen</li>
        <li>Bedarfsanalyse der Nutzung unserer Produkte, Dienstleistungen und Websites zwecks direkter Kundenansprache</li>
        <li>Werbung oder Markt- und Meinungsforschung, soweit Sie nicht widersprochen haben</li>
        <li>Geltendmachung rechtlicher Ansprüche und Verteidigung bei rechtlichen Streitigkeiten</li>
        <li>Gewährleistung der IT-Sicherheit und des IT-Betriebs der 1punkt2 UG (haftungsbeschränkt)</li>
        <li>Verhinderung und Aufklärung von Straftaten</li>
        <li>Übermittlung von Daten innerhalb der 1punkt2 UG (haftungsbeschränkt), sofern es für die Abwicklung der jeweiligen Geschäftsbeziehung erforderlich ist</li>
      </ul>
      <p>
        Unser Interesse an der jeweiligen Verarbeitung ergibt sich aus den jeweiligen Zwecken und ist im Übrigen wirtschaftlicher Natur (effiziente Aufgabenerfüllung, Vertrieb, Vermeidung von Rechtsrisiken). Soweit es der konkrete Zweck gestattet, verarbeiten wir Ihre Daten pseudonymisiert oder anonymisiert.
      </p>

      <h3>Bei Zugriffen aus dem Internet</h3>
      <p>
        Wenn Sie unsere Webseiten besuchen, speichern unsere Webserver temporär jeden Zugriff in einer Protokolldatei. Folgende Daten werden erfasst und bis zur automatisierten Löschung gespeichert:
      </p>
      <ul>
        <li>Anonymisierte IP-Adresse des anfragenden Rechners</li>
        <li>Datum und Uhrzeit des Zugriffs</li>
        <li>Name und URL der abgerufenen Daten</li>
        <li>Meldung, ob der Abruf erfolgreich war</li>
        <li>Erkennungsdaten des verwendeten Browser- und Betriebssystems</li>
        <li>Webseite, von der aus der Zugriff erfolgt</li>
        <li>Name Ihres Internet-Zugangs-Providers</li>
      </ul>
      <p>
        Die rechtmäßige Verarbeitung dieser Daten erfolgt zum Zweck, die Nutzung der Webseite zu ermöglichen (Verbindungsaufbau), der Systemsicherheit, der technischen Administration der Netzinfrastruktur sowie zur Optimierung des Internetangebotes. Indem Sie dieser Datenschutzerklärung zustimmen, geben Sie Ihre Einwilligung, dass wir diese Daten erheben dürfen. Sie haben die Möglichkeit dieser Datenverarbeitung zu widersprechen. Sofern Sie der Nutzung der Daten widersprechen, weisen wir Sie darauf hin, dass ggf. nur eine eingeschränkte Nutzung unserer Dienste möglich ist.
      </p>
      <p>
        Über die vorstehend genannten Fälle hinaus werden diese personenbezogenen Daten nicht verarbeitet, es sei denn, Sie willigen ausdrücklich in eine weitergehende Verarbeitung ein.
      </p>

      <h3>Datenverarbeitung</h3>
      <p>
        Ihre Daten werden innerhalb der 1punkt2 UG (haftungsbeschränkt) weitergegeben, sofern dies zur Erfüllung unserer vertraglichen und gesetzlichen Pflichten erforderlich ist oder die innerbetriebliche Organisation die Weitergabe erfordert (z. B. Finanzbuchhaltung und Vertrieb und Marketing). Innerhalb unseres Unternehmens wurden angemessene und den gesetzlichen Anforderungen entsprechende Vorgaben zum Schutz Ihrer personenbezogenen Daten getroffen.
      </p>
      <p>
        Ihre personenbezogenen Daten werden nicht an Dritte (Stellen außerhalb 1punkt2 UG (haftungsbeschränkt)) weitergegeben, es sei denn, Sie haben uns zuvor eine entsprechende Einwilligung erteilt oder es existiert eine gesetzliche Grundlage. Eine gesetzliche Verpflichtung kommt insbesondere bei folgenden Empfängern in Betracht:
      </p>
      <ul>
        <li>Öffentliche Stellen, Aufsichtsbehörden und -organe, wie z. B. Steuerbehörden</li>
        <li>Rechtsprechungs-/Straf­verfolgungsbehörden, wie z. B. Polizei, Staatsanwaltschaften, Gerichte</li>
        <li>Anwälte und Notare, wie z. B. in Insolvenzverfahren</li>
        <li>Wirtschaftsprüfer</li>
      </ul>
      <p>
        Darüber hinaus setzen wir verschiedene Dienstleister (Auftragsverarbeiter nach Art. 28 DSGVO) ein, die wir vertraglich nach den Vorgaben der DSGVO verpflichten und deren Einhaltung wir überwachen. Dabei handelt es sich u.a. um Unternehmen aus den Bereichen IT­Dienstleistung, Verwaltungsdienstleistung, Steuerdienstleistung oder Vertrieb und Marketing. Auftragsverarbeiter dürfen personenbezogene Daten nur nach unserer Weisung und zweckgebunden verwenden.
      </p>
      <p>
        Ausgenommen hiervon ist die Weitergabe an Dienstleistungspartner, wie etwa Paketdienstleister oder Speditionen, soweit die Übermittlung zur Bestellabwicklung bzw. Lieferung der Waren erforderlich ist. Die Logistikdienstleister erhalten die zur Auslieferung erforderlichen Daten zur eigenverantwortlichen Nutzung. Wir beschränken uns dabei auf die Übersendung der für die Auslieferung notwendigen Daten.
      </p>

      <h2 class="bold">Zweck der Datenverarbeitung</h2>
      <p>
        Gemäß dem Grundsatz der Datenvermeidung und Datensparsamkeit der Datenschutzgrundverordnung erheben wir auf unserer Website personenbezogene Daten nur, wenn diese für den von Ihnen gewünschten Zweck entweder erforderlich sind, wir aufgrund rechtlicher Vorschriften oder Vertrag dazu verpflichtet sind, wenn wir ein berechtigtes Interesse haben und/oder, wenn Sie uns diese freiwillig angeben.
      </p>
      <p>
        Bei der Eingabe persönlicher oder geschäftlicher Daten (z. B. E-Mail-Adresse, Name, Anschrift) erfolgt die Preisgabe Ihrer Daten auf ausdrücklich freiwilliger Basis.
      </p>
      <p>
        Ihre Kontakt-, Geschäfts- und Business relevanten Daten verarbeiten wir aufgrund rechtlicher Vorschriften im Rahmen des bestehenden oder sich anbahnenden Geschäftsverhältnisses. Zudem erklären Sie sich durch die Eingabe dazu bereit, dass die eingegebenen Daten zu dem legitimierten Zweck oder für den von Ihnen benannten Zweck erhoben, verarbeitet oder genutzt werden. Ihre angegebenen Daten werden nur solange von uns verarbeitet und gespeichert wie es der Zweck erfordert und werden nach Zweckerfüllung oder nach Ablauf der jeweiligen Aufbewahrungsfristen gelöscht. Eine Erhebung, Verarbeitung oder Nutzung zu einem anderen Zweck erfolgt nicht. Ihre Einwilligung in die Datennutzung kann jederzeit mit Wirkung für die Zukunft widerrufen werden. Sofern Sie der Nutzung der Daten widersprechen, weisen wir Sie darauf hin, dass ggf. nur eine eingeschränkte Nutzung unserer Dienste möglich ist.
      </p>
      <p>
        Folgendes sind Möglichkeiten, wie wir die Daten verwenden können:
      </p>

      <h3>Aufgrund gesetzlicher Vorschriften</h3>
      <ul>
        <li>Durchsetzung unserer Allgemeinen Geschäftsbedingungen</li>
        <li>Verwaltung unseres Geschäfts</li>
        <li>Schutz vor oder Ermittlung möglicher betrügerischer Transaktionen</li>
      </ul>

      <h3>Aufgrund vertraglicher Zwecke</h3>
      <ul>
        <li>Zahlungsbearbeitung für Käufe und andere Dienstleistungen</li>
      </ul>

      <h3>Aufgrund Ihrer Einwilligung</h3>
      <ul>
        <li>Kontaktaufnahme</li>
        <li>Versand von Produkten und Informationen</li>
        <li>Anmeldung bei Preisausschreiben, Programmen oder Angeboten auf Ihren Wunsch</li>
        <li>Lieferung sonstiger Dienstleistungen, die wir Ihnen angeboten haben</li>
        <li>Umfragen auf unseren Websites</li>
        <li>Entwicklung und Bereitstellung von Werbung, die an Ihre Interessen angepasst ist</li>
      </ul>

      <h3>Aufgrund unseres berechtigten Interesses</h3>
      <ul>
        <li>Ermittlung der Effektivität unserer Werbung</li>
        <li>Entwicklung neuer Produkte und Dienstleistungen</li>
        <li>Analyse der Nutzung unserer Produkte, Dienstleistungen und Websites</li>
        <li>Kenntnis, wie Sie auf unsere Website gelangt sind</li>
      </ul>

      <h2 class="bold">Dauer der Datenspeicherung</h2>
      <p>
        Soweit erforderlich, verarbeiten wir Ihre personenbezogenen Daten für die Dauer der Geschäftsbeziehung, dies umfasst die Anbahnung und Abwicklung dieser sowie die Aufbewahrung aufgrund gesetzlicher Aufbewahrungsfristen.
      </p>
      <p>
        Sind die Daten für die Erfüllung vertraglicher oder gesetzlicher Pflichten nicht mehr erforderlich, werden sie gelöscht. Es sei denn, es sprechen gesetzliche Pflichten des Verantwortlichen gegen eine Löschung. Dies kann u.a. der Fall sein zu folgenden Zwecken:
      </p>
      <ul>
        <li>Erfüllung handels- und steuerrechtlicher Aufbewahrungspflichten nach z. B. Handelsgesetzbuch (HGB), Abgabenordnung (AO), Geldwäschegesetz (GwG). Die dort vorgegebenen Fristen zur Aufbewahrung bzw. Dokumentation betragen zwei bis zehn Jahre.</li>
        <li>Erhaltung von Beweismitteln im Rahmen der gesetzlichen Verjährungsvorschriften. Nach den §§ 195 ff. des Bürgerlichen Gesetzbuches (BGB) können diese Verjährungsfristen bis zu 30 Jahre betragen, wobei die regelmäßige Verjährungsfrist drei Jahre beträgt</li>
      </ul>

      <h2 class="bold">Minderjährigenschutz</h2>
      <p>
        In der Regel übermitteln Kinder und Personen unter 18 Jahren ohne Zustimmung der Eltern oder Erziehungsberechtigten keine personenbezogenen Daten an uns. Wir fordern keine personenbezogenen Daten von Kindern an und wir versichern, nicht wissentlich personenbezogene Daten von Kindern zu sammeln, in irgendeiner Weise zu verwenden oder Dritten gegenüber unberechtigt offen zu legen.
      </p>

      <h2 class="bold">Verarbeitung Ihrer Kommunikationsanfragen</h2>
      <p>
        Wenn Sie uns per Kontaktformular, Email oder Telefon Anfragen zukommen lassen, werden Ihre Angaben aus dem Anfrageformular, der Email oder dem Telefonat inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
      </p>

      <h2 class="bold">Weitergabe der Daten an Dritte</h2>
      <p>
        Ihre personenbezogenen Daten werden nicht an Dritte (also außerhalb der 1punkt2 UG (haftungsbeschränkt)) weitergegeben, es sei denn, Sie haben uns zuvor eine entsprechende Einwilligung erteilt. Ausgenommen hiervon ist die Weitergabe an Dienstleistungspartner, wie etwa Paketdienstleister oder Speditionen, soweit die Übermittlung zur Bestellabwicklung bzw. Lieferung der Waren erforderlich ist. Die Logistikdienstleister erhalten die zur Auslieferung erforderlichen Daten zur eigenverantwortlichen Nutzung. Wir beschränken uns dabei auf die Übersendung der für die Auslieferung notwendigen Daten.
      </p>
      <p>
        Darüber hinaus werden noch andere Dienstleister für die Vertragsanbahnung und Vertragsabwicklung tätig, wie bspw. der Hosting Dienstleister für die Website. Diese Unternehmen werden im Rahmen einer sog. Auftragsverarbeitung für die 1punkt2 UG (haftungsbeschränkt) tätig und dürfen die personenbezogenen Daten nur nach unserer Weisung verwenden.
      </p>
      <p>
        Ausgenommen ferner ist die Übermittlung von Daten zur Bestellabwicklung bzw. Lieferung der Waren bzw. Serviceleistungen oder zu Buchhaltungs- und Abrechnungszwecken innerhalb der 1punkt2 UG (haftungsbeschränkt) übermittelt und verwendet. Zur Weitergabe der Daten können diese in der Cloudanwendung Invoiz der Buhl Data Service GmbH gespeichert werden. Darüber hinaus findet keine Übermittlung an Dritte ohne Ihre Einwilligung statt. In all diesen Fällen erfolgt die Übermittlung entsprechend der geltenden nationalen und europäischen Datenschutzbestimmungen; der Umfang der übermittelten Daten beschränkt sich dabei nur auf das erforderliche Minimum.
      </p>

      <h2 class="bold">Verwendung von Cookies</h2>
      <p>
        Unsere Website verwendet Cookies. Das sind kleine Textdateien, die Ihr Webbrowser auf Ihrem Endgerät speichert. Cookies helfen uns dabei, unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen.
      </p>
      <p>
        Einige Cookies sind “Session-Cookies.” Solche Cookies werden nach Ende Ihrer Browser-Sitzung von selbst gelöscht. Hingegen bleiben andere Cookies auf Ihrem Endgerät bestehen, bis Sie diese selbst löschen. Solche Cookies helfen uns, Sie bei Rückkehr auf unserer Website wiederzuerkennen.
      </p>
      <p>
        Mit einem modernen Webbrowser können Sie das Setzen von Cookies überwachen, einschränken oder unterbinden. Viele Webbrowser lassen sich so konfigurieren, dass Cookies mit dem Schließen des Programms von selbst gelöscht werden. Die Deaktivierung von Cookies kann eine eingeschränkte Funktionalität unserer Website zur Folge haben.
      </p>
      <p>
        Das Setzen von Cookies, die zur Ausübung elektronischer Kommunikationsvorgänge oder der Bereitstellung bestimmter, von Ihnen erwünschter Funktionen (z.B. Warenkorb) notwendig sind, erfolgt auf Grundlage von Art. 6 Abs. 1 Buchstabe f DSGVO. Als Betreiber dieser Website haben wir ein berechtigtes Interesse an der Speicherung von Cookies zur technisch fehlerfreien und reibungslosen Bereitstellung unserer Dienste. Sofern die Setzung anderer Cookies (z.B. für Analyse-Funktionen) erfolgt, werden diese in dieser Datenschutzerklärung separat behandelt.
      </p>

      <h2 class="bold">SSL- bzw. TLS-Verschlüsselung</h2>
      <p>
        Aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, die Sie an uns als Seitenbetreiber senden, nutzt unsere Website eine SSL-bzw. TLS-Verschlüsselung. Damit sind Daten, die Sie über diese Website übermitteln, für Dritte nicht mitlesbar. Sie erkennen eine verschlüsselte Verbindung an der „https://“ Adresszeile Ihres Browsers und am Schloss-Symbol in der Browserzeile. Wenn die SSL Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.
      </p>

      <h2 class="bold">Website-Analyse mit Google Analytics</h2>
      <p>
        Unsere Website verwendet Funktionen des Webanalysedienstes Google Analytics. Anbieter des Webanalysedienstes ist die Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.
      </p>
      <p>
        Google Analytics verwendet "Cookies." Das sind kleine Textdateien, die Ihr Webbrowser auf Ihrem Endgerät speichert und eine Analyse der Website-Benutzung ermöglichen. Mittels Cookie erzeugte Informationen über Ihre Benutzung unserer Website werden an einen Server von Google übermittelt und dort gespeichert. Server-Standort ist im Regelfall die USA.
      </p>
      <p>
        Das Setzen von Google-Analytics-Cookies erfolgt auf Grundlage von Art. 6 Abs. 1f DSGVO. Als Betreiber dieser Website haben wir ein berechtigtes Interesse an der Analyse des Nutzerverhaltens, um unser Webangebot und ggf. auch Werbung zu optimieren.
      </p>

      <h3>IP-Anonymisierung</h3>
      <p>
        Wir setzen Google Analytics in Verbindung mit der Funktion IP-Anonymisierung ein. Sie gewährleistet, dass Google Ihre IP-Adresse innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum vor der Übermittlung in die USA kürzt. Es kann Ausnahmefälle geben, in denen Google die volle IP-Adresse an einen Server in den USA überträgt und dort kürzt. In unserem Auftrag wird Google diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über Websiteaktivitäten zu erstellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber uns zu erbringen. Es findet keine Zusammenführung der von Google Analytics übermittelten IP-Adresse mit anderen Daten von Google statt.
      </p>
      <p>
        Das Setzen von Cookies durch Ihren Webbrowser ist verhinderbar. Einige Funktionen unserer Website könnten dadurch jedoch eingeschränkt werden. Ebenso können Sie die Erfassung von Daten bezüglich Ihrer Website-Nutzung einschließlich Ihrer IP-Adresse mitsamt anschließender Verarbeitung durch Google unterbinden. Dies ist möglich, indem Sie das über <a class="unstyled font-weight-bold" href="https://tools.google.com/dlpage/gaoptout?hl=de" target="_blank" rel="noopener">folgenden Link</a> erreichbare Browser-Plugin herunterladen und installieren.
      </p>

      <h3>Widerspruch gegen die Datenerfassung</h3>
      <p>
        Sie können die Erfassung Ihrer Daten durch Google Analytics verhindern, indem Sie auf folgenden Link klicken. Es wird ein Opt-Out-Cookie gesetzt, der die Erfassung Ihrer Daten bei zukünftigen Besuchen unserer Website verhindert:
      </p>
      <button class="comp-button" (click)="setGoogleAnalyticsOptOut(!optedOut)">
        Google Analytics {{(optedOut ? '' : 'de')}}aktivieren
      </button>
      <p>
        Einzelheiten zum Umgang mit Nutzerdaten bei Google Analytics finden Sie in der <a class="unstyled font-weight-bold" href="https://support.google.com/analytics/answer/6004245?hl=de" target="_blank" rel="noopener">Datenschutzerklärung von Google</a>.
      </p>

      <h3>Auftragsverarbeitung</h3>
      <p>
        Zur vollständigen Erfüllung der gesetzlichen Datenschutzvorgaben haben wir mit Google einen Vertrag über die Auftragsverarbeitung abgeschlossen.
      </p>

      <h3>Demografische Merkmale</h3>
      <p>
        Unsere Website verwendet die Funktion “demografische Merkmale” von Google Analytics. Mit ihr lassen sich Berichte erstellen, die Aussagen zu Alter, Geschlecht und Interessen der Seitenbesucher enthalten. Diese Daten stammen aus interessenbezogener Werbung von Google sowie aus Besucherdaten von Drittanbietern. Eine Zuordnung der Daten zu einer bestimmten Person ist nicht möglich. Sie können diese Funktion jederzeit deaktivieren. Dies ist über die Anzeigeneinstellungen in Ihrem Google-Konto möglich oder indem Sie die Erfassung Ihrer Daten durch Google Analytics, wie im Punkt “Widerspruch gegen die Datenerfassung” erläutert, generell untersagen.
      </p>

      <h2 class="bold">Ihre Rechte als Betroffener</h2>
      <p>
        Sie haben jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der Datenverarbeitung sowie ein Recht auf Berichtigung, Sperrung, Einschränkung oder Löschung dieser Daten. Sie haben außerdem das Recht, die sie betreffenden personenbezogenen Daten, die sie einem Verantwortlichen bereitgestellt hat, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten.
      </p>
      <p>
        Ebenso können Sie eine von Ihnen erteilte Einwilligung zur Verarbeitung und Nutzung Ihrer Daten, die nicht aufgrund einer vorrangigen Gesetzesvorschrift oder unmittelbar zur Abwicklung eines bestehenden Vertragsverhältnisses erforderlich ist, jederzeit widerrufen. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich unter der im Impressum angegebenen Adresse an uns wenden. Auf Anforderung teilen wir Ihnen schriftlich entsprechend dem geltenden Recht mit, ob und welche persönlichen Daten über Sie bei uns gespeichert sind.
      </p>
      <p>
        Nach der Datenschutzgrundverordnung haben unsere Kunden u.a. ein Recht auf unentgeltliche Auskunft über ihre gespeicherten Daten. Auf Anforderungen wird Ihnen schriftlich entsprechend dem geltenden Recht mitgeteilt werden, ob und welche persönlichen Daten wir über Sie gespeichert haben.
      </p>

      <h3>Sie sind Kunde bei der 1punkt2 UG (haftungsbeschränkt)</h3>
      <p>
        Richten Sie Ihre Anfrage per Mail an <a class="unstyled font-weight-bold" href="mailto:info@1punkt2.de">info@1punkt2.de</a>. Auf Anforderung wird Ihnen schriftlich gemäß geltendem Recht mitgeteilt, ob und welche persönlichen Daten wir über Sie gespeichert haben.
      </p>

      <h3>Sie sind noch kein Kunde</h3>
      <p>
        Um sicherzustellen, dass personenbezogene Daten nicht an Dritte herausgegeben werden, richten Sie Ihre Anfrage bitte per Post unter eindeutiger Identifizierung Ihrer Person (z.B. durch Beifügen einer Kopie Ihres Personalausweises) an:
      </p>
      <p>
        Steffi Paasche<br>
        Im Wiesengrund 4<br>
        56459 Stahlhofen am Wiesensee<br>
        Deutschland
      </p>
      <p>
        Schwärzen Sie bitte alles außer Ihren Vor- und Nachnamen, Ihre Anschrift und Ihrem Geburtsdatum. Auch wenn die Zusendung für Sie mit Unannehmlichkeiten verbunden ist – aber nur so können wir sicherstellen, dass wir auch tatsächlich der anfragenden Person in rechtlich zulässiger Weise Auskunft erteilen.
      </p>

      <h2 class="bold">Ansprechpartner in Datenschutzangelegenheiten</h2>
      <p>
        Wenn Sie Fragen hinsichtlich der Verarbeitung Ihrer persönlichen Daten haben, können Sie sich direkt an unseren Datenschutzbeauftragten und sein Team wenden, die auch in Fällen von Auskunftsersuchen, Anträgen oder Beschwerden zur Verfügung stehen:
      </p>
      <p>
        Steffi Paasche<br>
        Im Wiesengrund 4<br>
        56459 Stahlhofen am Wiesensee<br>
        Deutschland
      </p>
    </div>
  </div>
</div>
