<comp-navbar [hidden]="!isComponentActivated"></comp-navbar>
<div navbarCloseOnClick>
  <router-outlet (activate)="isComponentActivated = true"></router-outlet>
</div>
<comp-footer [hidden]="!isComponentActivated"></comp-footer>
<div class="loading" *ngIf="!isComponentActivated">
  <img src="/assets/images/logo_1punkt2_250x250.png" alt="1punkt2 Logo">
</div>


<ngx-cookie-banner #cookie>
  <div class="banner-inner">
    <span>
      <span>
        Diese Seite nutzt Cookies und Analyse-Technologien von Dritten. <a class="no-decoration text-underline" routerLink="/datenschutz">Mehr erfahren</a>.
      </span>
    </span>
  
    <a
      role="button"
      class="ml-auto no-decoration text-decoration-none"
      (click)="cookie.dismiss()"
    >Einverstanden</a>  
  </div>
</ngx-cookie-banner>
