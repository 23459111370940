import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { HeaderConfig } from '../shared/components/header/header.component';
import { WindowRef } from '../shared/services/window.service';

@Component({
  selector: 'comp-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {

  header: HeaderConfig = {
    title: 'Datenschutzerklärung'
  }

  readonly gaProperty = 'UA-148938975-1';
  readonly disableString = 'ga-disable-' + this.gaProperty;
  public optedOut: boolean;

  constructor(
    private windowRef: WindowRef,
    @Inject(PLATFORM_ID) private platformId,
  ) { }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      if (this.windowRef.nativeWindow.document.cookie.indexOf(this.disableString + '=true') > -1) {
        this.windowRef.nativeWindow[this.disableString] = true;
      }
      this.updateGoogleAnalyticsStatus();
    }
  }

  private updateGoogleAnalyticsStatus(): void {
    this.optedOut = this.windowRef.nativeWindow[this.disableString];
  }

  public setGoogleAnalyticsOptOut(optOut: boolean): void {
    const cookie = `${this.disableString}=${optOut ? 'true' : 'false'}; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/`;

    this.windowRef.nativeWindow.document.cookie = cookie;
    this.windowRef.nativeWindow[this.disableString] = optOut;

    this.updateGoogleAnalyticsStatus();
  }

}
