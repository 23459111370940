import { Component, OnInit, Input } from '@angular/core';

export interface HeaderConfig {
  title: string;
  subtitle?: string
}

@Component({
  selector: 'comp-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input()
  config: HeaderConfig;

  constructor() { }

  ngOnInit() {
  }

}
