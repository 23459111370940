import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { TypedDocument } from '@ngx-prismic/client';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Homepage } from '../prismic/models/homepage.model';
import { HomepageService } from '../prismic/services/homepage.service';
import { SliceType } from '../prismic/models/shared/slices.model';

@Injectable()
export class HomepageResolver implements Resolve<TypedDocument<Homepage>> {

  constructor(
    private readonly homepageService: HomepageService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<TypedDocument<Homepage>> {
    return this.homepageService.getHomepage().pipe(
      catchError(err => {
        console.log('Failed to load homepage from Prismic.', err);
        const fallbackResponse: TypedDocument<Homepage> = {
          data: {
            body: [
              {
                slice_type: SliceType.KEYCONTENT,
                items: null,
                slice_label: null,
                primary: {
                  headline: [
                    {
                      text: '1punkt2 ist eine professionelle Agentur für Photographie und Videoproduktion spezialisiert auf Unternehmens&shy;darstellungen',
                      type: null,
                      spans: null
                    }
                  ],
                  subheadline: [
                    {
                      text: 'Was ist 1punkt2?',
                      type: null,
                      spans: null
                    }
                  ],
                  text: [
                    {
                      text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
                      type: null,
                      spans: null
                    },
                    {
                      text: 'It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
                      type: null,
                      spans: null
                    }
                  ],
                  image: {
                    url: 'assets/images/bike.jpeg',
                    alt: null,
                    copyright: null,
                    dimensions: null
                  }
                }
              }
            ],
            title: [
              {
                text: 'Unsere Fotographie und Videoproduktion für Ihr Unternehmen',
                type: null,
                spans: null
              }
            ],
            subtitle: [
              {
                text: 'Willkommen bei 1punkt2',
                type: null,
                spans: null
              }
            ],
            services: [
              {
                icon: [
                  {
                    text: 'alarm',
                    type: null,
                    spans: null
                  }
                ],
                servicetitle: [
                  {
                    text: 'Fotographie',
                    type: null,
                    spans: null
                  }
                ],
                servicedescription: [
                  {
                    text: 'Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
                    type: null,
                    spans: null
                  }
                ]
              },
              {
                icon: [
                  {
                    text: 'all_out',
                    type: null,
                    spans: null
                  }
                ],
                servicetitle: [
                  {
                    text: 'Firmenvideos',
                    type: null,
                    spans: null
                  }
                ],
                servicedescription: [
                  {
                    text: 'It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.',
                    type: null,
                    spans: null
                  }
                ]
              },
              {
                icon: [
                  {
                    text: 'autorenew',
                    type: null,
                    spans: null
                  }
                ],
                servicetitle: [
                  {
                    text: 'Produktfotographie',
                    type: null,
                    spans: null
                  }
                ],
                servicedescription: [
                  {
                    text: 'It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
                    type: null,
                    spans: null
                  }
                ]
              }
            ]
          },
          alternate_languages: null,
          first_publication_date: null,
          last_publication_date: null,
          href: null,
          id: null,
          lang: null,
          linked_documents: null,
          slugs: null,
          tags: null,
          type: null,
          uid: null
        };
        return of(fallbackResponse);
      })
    );
  }
}
