import { Injectable, Renderer2, Inject, PLATFORM_ID, RendererFactory2 } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class GalleryService {

  private renderer: Renderer2;
  private _currentImage: string;
  private _imageUrls: string[];
  private _isOpen = false;

  constructor(
    private readonly rendererFactory: RendererFactory2,
    @Inject(PLATFORM_ID) private readonly platformId: Object
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  get currentImage() {
    return this._currentImage;
  }

  get isOpen() {
    return this._isOpen;
  }

  private get currentImageIndex() {
    return this._imageUrls.findIndex(img => img === this._currentImage);
  }

  open(imageUrls: string[], currentImage: string) {
    this._imageUrls = imageUrls;
    this._currentImage = currentImage;
    this._isOpen = true;
    this.updateBodyClass();
  }

  close() {
    this._isOpen = false;
    this.updateBodyClass();
  }
  
  next() {
    if (this.currentImageIndex < (this._imageUrls.length - 1)) {
      this._currentImage = this._imageUrls[this.currentImageIndex + 1]
    } else {
      this._currentImage = this._imageUrls[0];
    }
  }

  prev() {
    if (this.currentImageIndex > 0) {
      this._currentImage = this._imageUrls[this.currentImageIndex - 1]
    } else {
      this._currentImage = this._imageUrls[this._imageUrls.length - 1];
    }
  }

  private updateBodyClass() {
    if (isPlatformBrowser(this.platformId)) {
      const className = 'overflow-hidden';
      const element = document.body;
      if (this._isOpen) {
        this.renderer.addClass(element, className);
      } else {
        this.renderer.removeClass(element, className);
      }
    }
  }
}
