import { Headings1, Headings2, Image, Link, Paragraphs, Slice, VideoEmbed } from '@ngx-prismic/client';

export enum SliceType {
    KEYCONTENT = 'keycontent',
    TEXT = 'text',
    IMAGE = 'image',
    VIDEO = 'video'
}

export type KeyContent = Slice<SliceType.KEYCONTENT, {
    slider_image: Image;
}, {
    headline: Headings1;
    subheadline: Headings2;
    text: Paragraphs;
    image?: Image;
    video?: Paragraphs;
}>;

export type TextSlice = Slice<SliceType.TEXT, object, {
    text: Paragraphs;
}>;

export type ImageSlice = Slice<SliceType.IMAGE, object, {
    image: Image;
    description: Paragraphs;
}>;

export type Video = Slice<SliceType.VIDEO, object, {
    youtubevideo: VideoEmbed;
    description: Paragraphs;
}>;
