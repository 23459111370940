import { Component, OnInit } from '@angular/core';
import { ServiceGridConfig } from '../shared/components/service-grid/service-grid.component';
import { HeaderConfig } from '../shared/components/header/header.component';

@Component({
  selector: 'comp-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  header: HeaderConfig = {
    title: 'Sie erreichen uns jederzeit.',
    subtitle: 'Wir sind für Sie da.'
  };

  services: ServiceGridConfig = {
    services: [
      {
        heading: 'Rufen Sie uns an',
        icon: 'phone',
        text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.'
      },
      {
        heading: 'E-Mail-Kontakt',
        icon: 'mail_outline',
        text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.'
      },
      {
        heading: 'Social Media',
        icon: 'favorite_border',
        text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.'
      }
    ]
  };

  constructor() { }

  ngOnInit() {
  }

}
