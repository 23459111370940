import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { TypedDocument } from '@ngx-prismic/client';
import { Observable } from 'rxjs';
import { Case } from '../prismic/models/case.model';
import { CaseService } from '../prismic/services/case.service';

@Injectable()
export class CaseResolver implements Resolve<TypedDocument<Case>> {

  constructor(private readonly caseService: CaseService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<TypedDocument<Case>> {
    return this.caseService.getCase(route.paramMap.get('uid'));
  }
}
