<nav class="navbar navbar-expand-lg navbar-light fixed-top d-flex flex-column p-0">
  <div class="w-100 bg-white">
    <div class="container py-2">
      <div class="row justify-content-between">
        <a class="navbar-brand d-flex align-items-center" routerLink="/">
          <img src="/assets/images/logo_1punkt2_250x250.png" class="brand-image ml-2 mr-3" alt="1punkt2 Logo">
          <span class="font-montserrat">1punkt2</span>
        </a>
        <button class="navbar-toggler border-0 outline-none" (click)="navbarService.toggleNavbar()">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse py-3" [class.show]="navbarService.isNavbarOpen">
          <ul class="navbar-nav text-right ml-auto mt-2 mt-md-0">
            <li *ngFor="let navItem of navItems" class="nav-item">
              <a class="nav-link p-0"
                 [class.color-black]="isCurrent(navItem)"
                 (click)="navbarService.closeNavbar()"
                 [title]="navItem.text"
                 [routerLink]="navItem.href"
                 [fragment]="navItem.fragment">
                {{navItem.text}}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</nav>
