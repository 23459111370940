import { Injectable } from '@angular/core';
import { PrismicService, TypedDocument } from '@ngx-prismic/client';
import Prismic from 'prismic-javascript';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PrismicDocumentType } from '../models/document-types';
import { Faq } from '../models/faq.model';

@Injectable({
  providedIn: 'root'
})
export class FaqService {

  constructor(private readonly prismicService: PrismicService) { }

  getEntries(): Observable<TypedDocument<Faq>> {
    const predicates = [
      Prismic.Predicates.at('document.type', PrismicDocumentType.FAQ)
    ];

    return this.prismicService.query<Faq>(predicates).pipe(
      map(response => response.results[0])
    );
  }
}
