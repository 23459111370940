<div [class.bg-dark-grey]="config.inverted">
  <div class="container py-5">
    <div class="row mb-5 py-5">
      <div class="col">
        <h1 [class.color-white]="config.inverted" [innerHTML]="config.text"></h1>
      </div>
    </div>
    <div class="row pb-5">
      <div class="col">
        <comp-button [config]="config.button"></comp-button>
      </div>
    </div>
  </div>  
</div>
