import { Component, Input, OnInit } from '@angular/core';
import { Impression } from '../../../prismic/models/case.model';
import { GalleryService } from '../../services/gallery.service';

@Component({
  selector: 'comp-image-grid',
  templateUrl: './image-grid.component.html',
  styleUrls: ['./image-grid.component.scss']
})
export class ImageGridComponent implements OnInit {

  private static MAX_IMAGE_AMOUNT = 10;

  @Input()
  images: Impression[];

  @Input()
  mode: 'light' | 'dark' = 'light';

  constructor(private readonly galleryService: GalleryService) { }

  ngOnInit() {
    if (this.images.length > ImageGridComponent.MAX_IMAGE_AMOUNT) {
      console.log(`No image grid layout is defined for ${this.images.length} images! Falling back to max amount of ${ImageGridComponent.MAX_IMAGE_AMOUNT}.`);
      this.images = this.images.slice(0, ImageGridComponent.MAX_IMAGE_AMOUNT);
    }
  }

  openGallery(impression: Impression) {
    this.galleryService.open(this.images.map(image => image.impression.url), impression.impression.url);
  }

}
