import { Component, OnInit } from '@angular/core';

export interface TeamMember {
  name: string;
  position: string;
  image: {
    src: string;
  },
  phone: string;
  email: string;
}

@Component({
  selector: 'comp-contact-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnInit {

  members: TeamMember[] = [
    {
      name: 'Steffi Paasche',
      position: 'Kommunikationsdesign und Fotografie',
      image: {
        src: '/assets/images/team/steffi.jpg'
      },
      phone: '0162 66 66 462',
      email: 'steffi.paasche@1punkt2.de'
    },
    {
      name: 'Waldemar Hauke',
      position: 'Konzeption und Videoproduktionen',
      image: {
        src: '/assets/images/team/waldemar.jpg'
      },
      phone: '0174 34 2829 0',
      email: 'waldemar.hauke@1punkt2.de'
    }
  ];

  constructor() { }

  ngOnInit() {
  }

}
