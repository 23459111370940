import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as Sentry from '@sentry/browser';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

export interface ContactFormResponse {
  customerMailSent: boolean;
  internalMailSent: boolean;
}

@Component({
  selector: 'comp-contact-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {

  submitted = false;
  success = false;
  failure = false;

  formGroup = new FormGroup({
    name: new FormControl(null, Validators.required),
    company: new FormControl(null),
    email: new FormControl(null, [Validators.required, Validators.email]),
    message: new FormControl(null, [Validators.required, Validators.minLength(30)]),
    privacyAccepted: new FormControl(false, Validators.requiredTrue)
  });

  get failMailtoHref(): string {
    const mail = 'info@1punkt2.de';
    const subject = 'Anfrage über Ihr Kontaktformular';
    const message = this.formGroup.value.message;
    return `mailto:${mail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(message)}`;
  }

  constructor(private readonly http: HttpClient) { }

  ngOnInit() {
  }

  submit() {
    if (this.formGroup.invalid) {
      return;
    }

    const reportErrorToSentry = (error?: any) => Sentry.captureEvent({
      message: 'Contact Form could not be sent',
      extra: {
        value: this.formGroup.value,
        error
      },
    });

    this.submitted = true;
    const url = '/assets/php/controllers/contact-form.controller.php';
    this.http.post<ContactFormResponse>(url, this.formGroup.value)
      .pipe(
        catchError(err => {
          reportErrorToSentry(err);
          this.failure = true;
          return of(undefined);
        })
      )
      .subscribe(response => {
        if (response && response.customerMailSent && response.internalMailSent) {
          this.success = true;
        } else {
          reportErrorToSentry();
          this.failure = true;
        }
      }, err => {
        reportErrorToSentry(err);
        this.failure = true;
      });
  }

}
