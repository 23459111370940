import { Component, OnInit } from '@angular/core';
import { HeaderConfig } from '../shared/components/header/header.component';

@Component({
  selector: 'comp-imprint',
  templateUrl: './imprint.component.html',
  styleUrls: ['./imprint.component.scss']
})
export class ImprintComponent implements OnInit {

  header: HeaderConfig = {
    title: 'Impressum'
  }

  constructor() { }

  ngOnInit() {
  }

}
