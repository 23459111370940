import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PrismicClientModule } from '@ngx-prismic/client';
import { PrismicModule } from '../prismic/prismic.module';
import { SharedModule } from '../shared/shared.module';
import { FaqComponent } from './components/faq/faq.component';
import { FormComponent } from './components/form/form.component';
import { TeamComponent } from './components/team/team.component';
import { ContactComponent } from './contact.component';
import { FaqResolver } from './faq.resolver';

@NgModule({
  declarations: [
    ContactComponent,
    TeamComponent,
    FormComponent,
    FaqComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    RouterModule,
    PrismicModule,
    PrismicClientModule
  ],
  providers: [
    FaqResolver
  ]
})
export class ContactModule { }
