import { isValidValue, setDefaultParagraphs } from '@ngx-prismic/client';
import { KeyContentConfig } from '../../shared/components/key-content/key-content.component';
import { ServiceGridConfig } from '../../shared/components/service-grid/service-grid.component';
import { Service } from '../models/shared/service.model';
import { KeyContent } from '../models/shared/slices.model';

export class PrismicRendererUtils {

  static toKeyContentConfig(slice: KeyContent): KeyContentConfig {
    const keyContentConfig: KeyContentConfig = {
      heading: slice.primary.headline[0].text,
      subheading: setDefaultParagraphs(slice.primary.subheadline, 'paragraph', '')[0].text,
      text: slice.primary.text.map(t => t.text)
    };

    if (slice.primary.image && slice.primary.image.url) {
      keyContentConfig.image = {
        src: slice.primary.image.url,
        alt: slice.primary.image.alt || null,
        title: slice.primary.headline[0].text || null
      };
    }

    if (isValidValue(slice.primary.video)) {
      keyContentConfig.video = slice.primary.video[0].text;
    }

    if (slice.items && slice.items.length > 0) {
      keyContentConfig.slider = slice.items
        .filter(item => !!item.slider_image)
        .map(item => {
          return {
            src: item.slider_image.url,
            alt: item.slider_image.alt || null,
            title: item.slider_image.alt || null
          }
        });
    }

    return keyContentConfig;
  }

  static toServiceGrid(services: Service[], heading = 'Erbrachte Leistungen', mode: 'light' | 'dark' = 'dark'): ServiceGridConfig {
    return {
      services: services.map(service => {
        return {
          icon: service.icon && service.icon.url ? service.icon.url : undefined,
          heading: service.servicetitle[0].text,
          text: service.servicedescription[0].text
        }
      }),
      heading,
      mode
    };
  }
}
