<div itemprop="author publisher" itemscope itemtype="https://schema.org/LocalBusiness">
  <meta itemprop="name" content="1punkt2">
  <meta itemprop="legalName" content="1punkt2 UG (haftungsbeschränkt)">
  <meta itemprop="email" content="info@1punkt2.de">
  <meta itemprop="telephone" content="0 26 63 – 911 2 922">
  <meta itemprop="mainEntityOfPage" [content]="baseUrl">
  <meta itemprop="logo image" [content]="baseUrl + '/assets/images/logo_1punkt2_250x250'">
  <div itemprop="address location" itemscope itemtype="https://schema.org/PostalAddress">
    <meta itemprop="streetAddress" content="Hauptstraße 1">
    <meta itemprop="addressLocality" content="Stahlhofen am Wiesensee">
    <meta itemprop="postalCode" content="56459">
  </div>
</div>
