import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { TypedDocument } from '@ngx-prismic/client';
import { Observable } from 'rxjs';
import { Faq } from '../prismic/models/faq.model';
import { FaqService } from '../prismic/services/faq.service';

@Injectable()
export class FaqResolver implements Resolve<TypedDocument<Faq>> {

  constructor(
    private readonly faqService: FaqService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<TypedDocument<Faq>> {
    return this.faqService.getEntries();
  }
}
