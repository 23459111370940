<div class="py-5" [class.bg-dark-grey]="config.mode === 'dark'" *ngIf="config.services.length > 0">
    <div class="container">
      <div class="row pt-5 mb-5" *ngIf="config.heading">
        <div class="col">
          <h1 class="bold" [innerHTML]="config.heading" [class.color-white]="config.mode === 'dark'"></h1>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6 col-lg-4 mb-5" *ngFor="let service of config.services">
          <object #iconObject *ngIf="service.icon" [data]="service.icon | safe: 'resourceUrl'"></object>
          <h2 class="bold" [innerHTML]="service.heading" [class.color-white]="config.mode === 'dark'"></h2>
          <p [innerHTML]="service.text" [class.color-white]="config.mode === 'dark'"></p>
        </div>
      </div>
    </div>
</div>
