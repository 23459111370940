import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MetaGuard } from '@ngx-meta/core';
import { CaseDetailComponent } from './modules/+case-detail/case-detail.component';
import { CaseResolver } from './modules/+case-detail/case.resolver';
import { HomeComponent } from './modules/+home/home.component';
import { ContactComponent } from './modules/+contact/contact.component';
import { FaqResolver } from './modules/+contact/faq.resolver';
import { ImprintComponent } from './modules/+imprint/imprint.component';
import { PrivacyComponent } from './modules/+privacy/privacy.component';
import { HomeCaseResolver } from './modules/+home/home-case.resolver';
import { HomepageResolver } from './modules/+home/homepage.resolver';

const routes: Routes = [
  {
    path: '',
    canActivate: [MetaGuard],
    children: [
      {
        path: '',
        component: HomeComponent,
        data: {
          meta: {
            title: '1punkt2',
            description: ''
          }
        },
        resolve: {
          cases: HomeCaseResolver,
          homepage: HomepageResolver
        }
      },
      {
        path: 'kontakt',
        component: ContactComponent,
        data: {
          meta: {
            title: 'Kontakt - 1punkt2',
            description: ''
          }
        },
        resolve: {
          faqEntries: FaqResolver
        }
      },
      {
        path: 'impressum',
        component: ImprintComponent,
        data: {
          meta: {
            title: 'Impressum - 1punkt2',
            description: ''
          }
        }
      },
      {
        path: 'datenschutz',
        component: PrivacyComponent,
        data: {
          meta: {
            title: 'Datenschutzerklärung - 1punkt2',
            description: ''
          }
        }
      },
      {
        path: 'referenzen/:uid',
        component: CaseDetailComponent,
        resolve: {
          case: CaseResolver
        }
      },
      {
        path: '**',
        pathMatch: 'full',
        redirectTo: ''
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 30]
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
