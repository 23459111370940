import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MetaLoader, MetaModule, MetaStaticLoader, PageTitlePositioning } from '@ngx-meta/core';
import { ScullyLibModule } from '@scullyio/ng-lib';
import { NgxCookieBannerModule } from 'ngx-cookie-banner';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CaseDetailModule } from './modules/+case-detail/case-detail.module';
import { ContactModule } from './modules/+contact/contact.module';
import { HomeModule } from './modules/+home/home.module';
import { ImprintModule } from './modules/+imprint/imprint.module';
import { PrivacyModule } from './modules/+privacy/privacy.module';
import { PrismicModule } from './modules/prismic/prismic.module';
import { SharedModule } from './modules/shared/shared.module';
import { SentryErrorHandler } from './sentry';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MetaModule.forRoot({
      provide: MetaLoader,
      useFactory: (metaFactory)
    }),
    HttpClientModule,
    SharedModule,
    PrismicModule,
    CaseDetailModule,
    HomeModule,
    ContactModule,
    ImprintModule,
    PrivacyModule,
    NgxCookieBannerModule.forRoot({
      cookieName: 'comp-1punkt2-webpage-cookie-consent',
    }),
    ScullyLibModule
  ],
  providers: [
    { provide: ErrorHandler, useClass: SentryErrorHandler }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function metaFactory(): MetaLoader {
  return new MetaStaticLoader({
    pageTitlePositioning: PageTitlePositioning.PrependPageTitle,
    pageTitleSeparator: ' - ',
    applicationName: '1punkt2',
    defaults: {
      description: '',
      'og:type': 'website',
      'og:locale': 'de_DE'
    }
  });
}
