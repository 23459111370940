import { Component, OnInit, Input } from '@angular/core';

export interface ServiceConfig {
  icon: string;
  heading: string;
  text: string;
}

export interface ServiceGridConfig {
  heading?: string;
  services: ServiceConfig[];
  mode?: 'light' | 'dark';
}

@Component({
  selector: 'comp-service-grid',
  templateUrl: './service-grid.component.html',
  styleUrls: ['./service-grid.component.scss']
})
export class ServiceGridComponent implements OnInit {

  @Input()
  config: ServiceGridConfig;

  constructor() { }

  ngOnInit() {
    this.config.mode = this.config.mode || 'light';
  }

}
