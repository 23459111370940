<div class="py-5" [class.bg-dark-grey]="mode === 'dark'">
  <div class="container py-5">
    <div class="row mb-5">
      <div class="col">
        <h1 class="bold" [class.color-white]="mode === 'dark'">Impressionen</h1>
      </div>
    </div>
    <!-- Normal row/col for small screens -->
    <div class="row d-md-none">
      <div class="col-12" *ngFor="let image of images">
        <div class="image-container position-relative rounded w-100 mb-3">
          <div [style.background-image]="'url(' + image.impression.url + ')'" (click)="openGallery(image)"></div> 
        </div>
      </div>
    </div>
    <!-- Grid-Based layouts for larger screens -->
    <!-- The specific layout is defined by the amount of images (box-X classes) in CSS -->
    <div class="row d-none d-md-block">
      <div class="wrapper">
        <div *ngFor="let image of images; let index = index"
             [ngClass]="'box box-' + images.length + ' box-' + images.length +'-image-' + (index + 1)"
             [style.background-image]="'url(' + image.impression.url + ')'"
             (click)="openGallery(image)">
        </div>
      </div>
    </div>
  </div>
</div>
