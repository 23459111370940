import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { CaseListComponent } from './components/case-list/case-list.component';
import { HomeComponent } from './home.component';
import { HomeCaseResolver } from './home-case.resolver';
import { HomepageResolver } from './homepage.resolver';

@NgModule({
  declarations: [
    HomeComponent,
    CaseListComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule
  ],
  providers: [
    HomeCaseResolver,
    HomepageResolver
  ]
})
export class HomeModule { }
