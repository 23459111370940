import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TypedApiSearchResponse } from '@ngx-prismic/client';
import { environment } from '../../../../../environments/environment';
import { Case } from '../../../prismic/models/case.model';

@Component({
  selector: 'comp-case-list',
  templateUrl: './case-list.component.html',
  styleUrls: ['./case-list.component.scss']
})
export class CaseListComponent implements OnInit {

  baseUrl = environment.baseUrl;

  cases: TypedApiSearchResponse<Case>;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.cases = this.route.snapshot.data.cases;
  }

}
