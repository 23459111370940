import { Component, ViewChild } from '@angular/core';
import { IdleMonitorService } from '@scullyio/ng-lib';
import { NgxCookieBannerComponent } from 'ngx-cookie-banner';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'comp-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(
    private readonly scullyIdle: IdleMonitorService
  ) {}

  isComponentActivated = false;
  
  @ViewChild('cookie')
  banner: NgxCookieBannerComponent;

  private _cookieSub: Subscription;

  // It is currently necessary to manually subscribe at this
  // point to initialize the banner component.
  ngAfterViewInit() {
    this._cookieSub = this.banner.isSeen.pipe(
      tap(consent => {
        if (consent && environment.tracking && environment.tracking.enable) {
          window['exportarts'].setupGTM();
          window['exportarts'].setupGTM = () => null;
        }
      })
    ).subscribe();
  }

  ngOnDestroy() {
    this._cookieSub.unsubscribe();
  }

}
