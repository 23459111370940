<div class="w-100 pb-5 bg-dark-grey">
  <img *ngIf="config.image"
        class="w-100"
        [src]="config.image.src"
        [attr.alt]="config.image.alt"
        [attr.title]="config.image.title">
  <div *ngIf="config.video" class="w-100 video-container">
    <iframe [src]="config.video | safe: 'resourceUrl'" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
  </div>
  <div class="container pb-5">
    <div class="row pt-5 mb-5">
      <div class="col-12">
        <h2 class="color-white bold" [innerHTML]="config.subheading"></h2>
      </div>
      <div class="col-12">
        <h1 class="color-white" [innerHTML]="config.heading"></h1>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6" *ngFor="let paragraph of config.text">
        <p class="color-white" [innerHTML]="paragraph"></p>
      </div>
    </div>
  </div>  
</div>
