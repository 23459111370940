import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MetaService } from '@ngx-meta/core';
import { TypedDocument } from '@ngx-prismic/client';
import { environment } from '../../../environments/environment';
import { Case } from '../prismic/models/case.model';
import { PrismicRendererUtils } from '../prismic/services/renderer-utils';
import { TeaserConfig } from '../shared/components/teaser/teaser.component';

@Component({
  selector: 'comp-case-detail',
  templateUrl: './case-detail.component.html',
  styleUrls: ['./case-detail.component.scss']
})
export class CaseDetailComponent implements OnInit {

  result: TypedDocument<Case>;
  RendererUtils = PrismicRendererUtils;

  teaser: TeaserConfig = {
    text: 'Ihnen gefallen unsere Referenzen?<br>Kontaktieren Sie uns und wir besprechen Ihr Vorhaben unverbindlich.',
    button: {
      routerLink: '/kontakt',
      text: 'Jetzt Kontakt aufnehmen'
    }
  };

  hasTextContent = false;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly meta: MetaService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.result = this.route.snapshot.data.case;
      this.setupMetaData();
    });
  }

  private setupMetaData() {
    // If only key-content is present, invert the color mode to maintain dark-light-pattern
    if (this.result.data.body.length > 1) {
      this.hasTextContent = true;
      this.teaser.inverted = true;
    }
    // Make sure teaser is always opposite color than section before (services)
    if (!this.result.data.services || this.result.data.services.length === 0) {
      this.teaser.inverted = !this.teaser.inverted;
    }

    this.meta.setTitle(`${this.result.data.title[0].text}`);
    this.meta.setTag('og:type', 'article');
    this.meta.setTag('description', this.result.data.description[0].text);
    this.meta.setTag('og:description', this.result.data.description[0].text);
    this.meta.setTag('og:title', this.result.data.title[0].text);
    this.meta.setTag('og:image', this.result.data.heroimage.url);
    this.meta.setTag('og:url', `${environment.baseUrl}/referenzen/${this.result.uid}`);
  }

}
