import { Environment } from './environment.model';

export const environment: Environment = {
  name: 'development',
  production: true,
  prismic: {
    url: 'https://www1punkt2de.cdn.prismic.io/api/v2'
  },
  baseUrl: 'https://development.1punkt2.de',
  sentry: {
    dsn: 'https://8f19652fbea941b48aed500bb367ffd4@sentry.io/1431970'
  }
};
