import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'comp-meta-local-business',
  templateUrl: './meta-local-business.component.html',
  styleUrls: ['./meta-local-business.component.scss']
})
export class MetaLocalBusinessComponent implements OnInit {

  baseUrl = environment.baseUrl;

  constructor() { }

  ngOnInit() {
  }

}
